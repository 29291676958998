.ecommerce-main {
  @include autosize2(margin-bottom, 60);

  &-text {
    text-align: center;
    @include autosize2(margin-bottom, 60);
  }

  .post-type-archive-product &, .tax-product_categories & {
    @media(min-width: $screen-md-min) {
      border-left: 1px solid $brown-lighter;
      margin-left: -1px;
      padding-left: $grid-gutter-width;
    }
  }
  .single-product & {
    @media(min-width: $screen-md-min) {
      border-right: 1px solid $brown-lighter;
      margin-right: -1px;
      padding-right: $grid-gutter-width;
    }
  }
}

.ecommerce-product {
  text-decoration: none !important;
  display: block;
  width: 100%;
  margin-bottom: 30px;

  &:hover {
    .ecommerce-product-content {
      background-color: lighten($gray-lighter, 4%);
    }
  }

  &-related {
    .title-brown {
      text-align: center;
      @include autosize2(margin-bottom, 30);
    }

    .related-carousel {
      position: relative;

      .owl-next, .owl-prev {
        position: absolute;
        background: $brown;
        color: $white;
        display: inline-block;
        width: 28px;
        height: 28px;
        font-size: 28px;
        line-height: 28px;
        @include autosize2(top, -60);
      }
      .owl-prev {
        @include responsivize(left, 0, 14%, 20%, 24%);
      }
      .owl-next {
        @include responsivize(right, 0, 14%, 20%, 24%);
      }
    }
  }
  &-image {
    background: $white;
    @include transition(transform .5s);
    @include scale(1);

    &:hover {
      @include scale(1.05);
    }
    &-box {
      border: 1px solid $brown-lighter !important;
      overflow: visible;
    }
  }
  &-content {

    .post-type-archive-product &, .tax-product_categories & {
      @include autosize2(margin-top, 20);
    }

    .single-product & {
      @media(max-width: $screen-xs-max) {
        margin-top: 20px;
      }
      @media(min-width: $screen-sm-min) {
        margin-top: 0;
      }
    }

    .ecommerce-product-related & {
      @include autosize2(margin-top, 20);
    }
  }
  &-actions {
    @include autosize2(margin-top, 10);

    .single-product & {
      @include autosize2(margin-top, 20);
    }

    .input-group {
      @media(min-width: $screen-sm-min) {
          width: 200px;
      }

      .product-quantity {
        @include responsivize(height, 41px, 45px, 49px, 52px);
      }
    }
    .ecommerce-row {
      &+.ecommerce-row {
        @include autosize2(margin-top, 10);
      }
    }
    .btn-read-more {
      @include autosize2(font-size, 14, 0.8);
      white-space: nowrap;
    }
    .product-button-add {
      @include autosize2(padding-top, 15);
      @include autosize2(padding-bottom, 15);
      @include transition(all .5s);

      position: relative;
      text-transform: uppercase;

      .icon-wf-toujours_shop {
        position: absolute;
        font-size: 25px;
        top: -5px;
        left: 0;
      }

      &-inner {
        padding-left: 30px;
        padding-right: 5px;
        position: relative;
      }
    }
  }
  &-title {
    @include autosize2(font-size, 20);
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    color: $brown;
    vertical-align: top;
    display: table;
    width: 100%;
  }
  &-brands {
    margin: 5px 0 0;
    padding: 0;
    list-style: none;

    li {
      @include autosize2(font-size, 18);
      margin: 0;
      padding: 0;
      display: inline-block;
      color: $gray;
      font-weight: 100;

      & + li {
        &:before {
          content: "\2022";
        }
      }
    }
  }
  &-price {
    display: inline-block;
    float: right;
    @include autosize2(font-size, 25);
    @include autosize2(line-height, 30);
  }
}

.product {
  &-sale {
    @include autosize2(margin-top, 20);
    @include autosize2(margin-bottom, 20);
  }
  &-price {
    @include autosize2(font-size, 30);
  }

  &-content {
    @include autosize2(padding-top, 30);
    @include autosize2(margin-bottom, 30);
  }

  &-pagination {
    float: right;

    .screen-reader-text {
      display: none !important;
    }
  }
}