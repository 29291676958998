#cart_content,
#cart_sidebar_update,
.ecommerce-account-login,
.ecommerce-address-update,
.loader-area {
  display: block;
  position: relative;
  &:before,
  &:after {
    opacity: 0;
    transition: opacity 0.1s linear;
    z-index: 20;
  }
  &.loading {
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      opacity: 1;
    }
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.9);
    }
    &:after {
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      border: 3px solid #acacac;
      border-top-color: #3a3a3a;
      animation: rotate360 0.8s infinite linear;
    }
  }
}

#cart_content,
.ecommerce-account-login,
.ecommerce-address-update,
.loader-area-lg {
  &.loading {
    &:after {
      width: 80px;
      height: 80px;
      margin-top: -40px;
      margin-left: -40px;
      border-width: 4px;
    }
  }
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}