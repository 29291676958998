.cart-table {
  &-button-delete{
    cursor: pointer;
    color: $white;
    background: $beige;
    text-align: center;
    text-decoration: none !important;
    border-radius: 100%;
    padding: 0 0 2px 0;
    @include autosize2(width, 25);
    @include autosize2(height, 25);
    @include autosize2(line-height, 15);
    @include autosize2(font-size, 28);
    @include user-select(none);
    @include rotate(-45deg);
    @include transition(background .5s);

    &:hover,
    &:active,
    &:focus{
      color: $white;
      background: darken($beige, 10%);
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  &-bigger{
    font-size: 120%;
  }

  &-title {
    th {
      font-weight: $font-light;
    }
  }

  &-strong{
    font-weight: bold;
  }

  &-price {
    strong {
      white-space: nowrap;
    }
  }
}

.page-shop-order-detail {
  .table-container {
    border: 1px solid $brown-lighter;
    @include autosize2(padding, 20);
  }
}

.page-template-template-cart, .page-shop-order-detail, .page-template-template-account {

  .title-beige {
    margin-top: 0;
    @include autosize2(margin-bottom, 60);
  }

  .title-beige-min {
    text-transform: uppercase;
    @include autosize2(margin-top, 50);
  }
  
  .btn-logout {
    @include autosize2(margin-left, 30);
  }

  .ecommerce-form-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .ecommerce-forgot-link {
    text-align: right;
    
    a {
      @include autosize2(font-size, $font-menu-small);
      @include underline-from-left($brown-dark);
    }
  }

  .ecommerce-form-control {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid $brown-lighter;

    &:focus {
      border-bottom: 1px solid $brown;
    }
  }

  .cart-table {
    &-price {
      color: $beige;
      white-space: nowrap;
      font-weight: $font-regular;

      &-tot {
        @extend .cart-table-price;
        font-weight: $font-bold;
      }
    }
    &-link {
      @include underline-from-left($brown-dark, 2);
    }
  }

  .cart-shipping {
    &-price, &-label {
      text-align: right;
    }
    &-price {
      color: $beige;
    }
  }
  .cart-total {
    &-price, &-label {
      @include autosize2(font-size, 26);
      font-weight: $font-bold;
      text-align: right;
    }
    &-price {
      color: $beige;
      white-space: nowrap;
    }
  }
}

.ecommerce-update-item-qty-adv {
  input[name="qty"] {
    border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid $brown-lighter;
    color: $beige;
    height: 22px;
    margin: 0 10px;
    width: calc(100% - 20px);
    background: $white;
  }
  .ecommerce-btn {
    background: $brown;
    color: $white;
    padding: 0;
    font-size: 20px;
    line-height: 10px;

    &:hover, &:focus, &:active {
      background: darken($brown, 10%);
    }
  }
}

.td-responsive {
  @include autosize2(width, 160, .5);

  &-big {
    @include autosize2(width, 160, .8);
  }
}