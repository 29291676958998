@mixin responsivize($property, $xs-value, $sm-value, $md-value, $lg-value) {
  @media(max-width: $screen-xs-max){
    #{$property}: $xs-value;
  }
  @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    #{$property}: $sm-value;
  }
  @media(min-width: $screen-md-min) and (max-width: $screen-md-max){
    #{$property}: $md-value;
  }
  @media(min-width: $screen-lg-min){
    #{$property}: $lg-value;
  }
}

@mixin autosize($property, $xs-value, $lg-value) {
  @include responsivize(
                  $property,
                  $xs-value * 1px,
                  $xs-value + ( ( $lg-value - $xs-value ) * 0.33 ) * 1px,
                  $xs-value + ( ( $lg-value - $xs-value ) * 0.66 ) * 1px,
                  $lg-value * 1px);
}

@mixin autosize2($property, $lg-value, $factor: 0.65) {
  $xs-value: $lg-value * $factor;
  @include autosize($property, $xs-value, $lg-value);
}

@mixin underline-from-left($color: $text-color, $size: 1, $bottom: 0) {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;

  &:before {
    content: " ";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: $bottom * 1px;
    background: $color;
    height: $size * 1px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  &:hover:before, &:focus:before, &:active:before {
    right: 0;
  }
}

.bg-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $gray-lighter;
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.vertical-center{
  vertical-align: middle;
}