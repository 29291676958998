
.ecommerce-sidebar {
  .title-beige-min {
    text-transform: uppercase;
    @include autosize2(margin-bottom, 30);
  }

  .post-type-archive-product &, .tax-product_categories & {
    @media(min-width: $screen-md-min) {
      border-right: 1px solid $brown-lighter;
      padding-right: $grid-gutter-width;
    }
  }
  .single-product & {
    @media(min-width: $screen-md-min) {
      border-left: 1px solid $brown-lighter;
      padding-left: $grid-gutter-width;
    }
  }

  .ecommerce-search {
    .search-form {
      border-top: 1px solid $brown-lighter;
      margin-top: -1px;
      border-bottom: 1px solid $brown-lighter;
      @include autosize2(padding-top, 50);
      @include autosize2(padding-bottom, 40); 
    }
  }
}

.ecommerce-categories-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $brown-lighter;
  @include autosize2(padding-bottom, 30);

  &-toggle {
    text-decoration: none !important;
    color: $beige !important;

    [class^=icon-wf] {
      display: none;
    }
  }

  @media(min-width: $screen-md-min) {
    &-toggle {
      pointer-events: none;
      cursor: default;
    }
  }
  @media(max-width: $screen-sm-max) {
    display: none;

    .ecommerce-categories-list-opened & {
      display: block;

      &-toggle {
        .icon-wf-toujours_minus {
          display: inline-block;
        }
        .icon-wf-toujours_plus {
          display: none;
        }
      }
    }

    &-toggle {
      display: inline-block;

      &-text {
        margin-left: 10px;
      }

      &:hover, &:active, &:focus {
        color: darken($beige, 10%);
      }

      .icon-wf-toujours_minus {
        display: none;
      }
      .icon-wf-toujours_plus {
        display: inline-block;
      }
    }
  }

  li {
    width: 100%;
    background: #FFFFFF;

    a {
      padding: 10px 0;
      width: 100%;
      color: $brown;
      @include underline-from-left($beige);

      span {
        display: block;
        white-space: nowrap;
      }
    }

    @media(max-width: $screen-sm-max) {
      display: inline-block;
      width: auto;
      @include autosize2(margin-right, 50);
    }
  }
}

.ecommerce-cart-sidebar {

  &-title {
    @include autosize2(margin-top, 30);
  }

  &-detailed {
    @extend .ecommerce-cart-sidebar;

    .ecommerce-cart-sidebar-title {
      margin-top: 0;
    }
    
    .cart-table-price {
      @include autosize2(line-height, 36);
      @include autosize2(font-size, 18);
      color: $beige;
    }
    
    .cart-product-item {
      @include autosize2(margin-bottom, 20);
      @include autosize2(padding-bottom, 25);
      border-bottom: 1px solid $brown-lighter;
    }

    .cart-product-total {
      text-align: right;
      @include autosize2(margin-bottom, 30);
      @include autosize2(font-size, 30);
    }
  }

  .cart-sidebar-content {
    @include autosize2(margin-bottom, 30);

    p {
      @include autosize2(font-size, 18);
      background: $brown;
      color: $white;
      padding: 15px 10px;
      margin-bottom: 0;
      text-align: center;
    }

    &-btn {
      background: $beige;
      text-align: center;
      padding: 10px;
    }
  }
}